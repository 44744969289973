import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsDropdownDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { LoggerService } from 'src/app/services/logging/logger.service';
import { UserAuthService } from 'src/app/services/user/user-auth.service';
import { MyTranslateService } from '../../my-translate.service';

const PARAMS = {
  search: "",
  limit: 20,
  page: 0,
  offset: ''
}

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit, OnDestroy {

  @ViewChild('dropdown', {read: ElementRef, static: true}) dropdownElement !: ElementRef<HTMLDivElement>;
  @ViewChild('dropdown', {read: BsDropdownDirective, static: true}) dropdownDirective !: BsDropdownDirective;

  @Input() dropdownPosition: "left" | "right" = "left";
  @Input() theme: "dark" | "light" = "dark";

  languageForm!: FormGroup;

  data  : any[]= []

  selectedData?: any;

  userDataSubscription !: Subscription;

  @HostListener('document:click', ['$event.target'])
    public onClick(targetElement : any) {
      const isClickedInside = this.dropdownElement.nativeElement.contains(targetElement);
      if (!isClickedInside) {
          this.dropdownDirective.hide();
      }
    }

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    private user: UserAuthService,
    private translate: MyTranslateService
  ) {
   }

  async ngOnInit() {
    this.languageForm = this.fb.group({
      language: []
    });
    this.user.getUserDataSubject().subscribe(async res => {
      await this.getMstLanguage();
      if (res) {
        this.selectedData = this.data.find(el => res.data_user.id_mst_language == el.id);
        if (this.selectedData == undefined) {
          const localLangID = localStorage.getItem("id_mst_language");
          if (localLangID !== null) this.selectedData = this.data.find(el => localLangID == el.id);
        }
      } else {
        const localLangID = localStorage.getItem("id_mst_language");
        if (localLangID !== null) this.selectedData = this.data.find(el => localLangID == el.id);
      }

      if (this.selectedData == undefined) {
        this.selectedData = this.data[0];
      }
    })
  }

  ngOnDestroy(): void {
    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();
  }

  private async getMstLanguage(){    
    try {
      const res = await this.translate.getMstLanguage();
      this.data = res.data;
    } catch (error) {
      this.logger.error(error);
    }
  }

  async modelChange(data : any){
    this.selectedData = data;
    this.languageForm.get('language')?.patchValue([]);
    try {
      if (this.user.isLoggedIn()) {
        this.user.updateUserLocation(data.country_code);
      } else {
        await this.translate.changelanguage(data.id);
      }
      //location.reload();
    } catch (error) {
      this.logger.error(error)
    }
  }
}
