import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmergencyButtonComponent } from './components/emergency-button/emergency-button.component';
import { EmergencyPopupComponent } from './components/emergency-popup/emergency-popup.component';
import { ModalModule } from 'angular-bootstrap-md';



@NgModule({
  declarations: [
    EmergencyButtonComponent,
    EmergencyPopupComponent
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot()
  ],
  exports: [
    EmergencyButtonComponent,
    EmergencyPopupComponent
  ]
})
export class EmergencyModule { }
