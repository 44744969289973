import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MyTranslateService } from 'src/app/modules/my-translate/my-translate.service';
import { getMstSetting } from 'src/app/services/api/master.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectToPlaystoreGuard implements CanActivate {

  constructor(
    private translate: MyTranslateService,
    private router: Router,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      let tree = this.router.parseUrl("/")
      getMstSetting(await this.translate.id_lang).then(res => {
        if (res?.phone_android_link) {
          window.location.href = res?.phone_android_link;
          resolve(false);
        }
        resolve(tree);
      }).catch(err => {
        resolve(tree);
      })
    })
  }
}
