<div mdbModal #modal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modal.hide()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="h3 font-weight-bold mb-3 text-center">Layanan Darurat</div>
            <div class="row">
                <div class="col-6 col-md-4 emergency-grid" *ngFor="let item of emergencyServices">
                    <div class="emergency-box text-center h-100">
                        <img class="icon mb-2" [src]="item.icon"/>
                        <div class="font-weight-bold">{{item.label}}</div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>