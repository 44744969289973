import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { MyThemesService } from '../../services/my-themes.service';

@Component({
  selector: 'app-my-theme-selector',
  templateUrl: './my-theme-selector.component.html',
  styleUrls: ['./my-theme-selector.component.scss']
})
export class MyThemeSelectorComponent  {

  constructor(
    public svc: MyThemesService,
    private renderer: Renderer2,
  ) { }


  themeChange(newValue: any){
    const previous = this.svc.selected?.value
    if (previous) this.renderer.removeClass(document.body, previous)
    if (newValue.value) this.renderer.addClass(document.body, newValue.value);
    this.svc.selected = newValue;
    this.svc.saveTheme(newValue);
  }

}
