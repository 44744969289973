import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const ThemeStorageKey = 'assethub_theme'

@Injectable({
  providedIn: 'root'
})
export class MyThemesService {

  readonly defaultThemes = [
    {
      label: 'Original',
      value: null
    }
  ]

  themes: any[] = []

  selected?: any = this.themes[0];

  constructor(
    private http: HttpClient
  ) {
    this.http.get('/assets/themes/themes.json', { headers: { Accept: 'application/json' } }).subscribe(
      (res: any) => {
        this.themes = res;
      },
      err => {
        this.themes = this.defaultThemes;
      }, 
      () => {this.initTheme();}
      
    ) 
  }

  initTheme(){
    const savedTheme = localStorage.getItem(ThemeStorageKey) ?? 'my-theme-wood';
    this.selected = this.themes.find(item => item.value == savedTheme) ?? this.themes[0]
    this.selected.value && document.body.classList.add(this.selected.value);
  }

  saveTheme(theme: any){
    localStorage.setItem(ThemeStorageKey, theme?.value ?? undefined);
  }
}
