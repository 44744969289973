import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {

  private enableBackButton = false;

  constructor(
    private location: Location,
  ) {
    this.initBackButtonOnMobile()
  }

  enableBackButtonFunction(){
    this.enableBackButton = true;
  }

  disableBackButtonFunction(){
    this.enableBackButton = false;
  }

  //Exit app if can't go back
  private  async initBackButtonOnMobile(){
    if (isPlatform("android") && isPlatform("capacitor")) {
      let a = await App.addListener('backButton', (event) => {
        if (!this.enableBackButton) return;
        if (!event.canGoBack) {
          App.exitApp();
        } else {
          this.location.back();
        }
      })
    }
  }
}
