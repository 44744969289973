import { Injectable } from '@angular/core';
import { isPlatform } from '@ionic/angular';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken, Messaging, onMessage } from "firebase/messaging";
import { Observable, Subject } from 'rxjs';
import { AndroidPluginService } from '../android/android-plugin.service';
import { addTokenFCM, deleteTokenFCM } from '../api/fcm.service';
import { LoggerService } from '../logging/logger.service';
import { UserAuthService } from '../user/user-auth.service';

const firebaseConfig = {
  apiKey: "AIzaSyAEuvXJgB1pRhHhnAbBYBCLADVh9s8jMqg",
  authDomain: "containder-e0bcf.firebaseapp.com",
  projectId: "containder-e0bcf",
  storageBucket: "containder-e0bcf.appspot.com",
  messagingSenderId: "793927774158",
  appId: "1:793927774158:web:ada8fb4bd48a81783e859f",
  measurementId: "G-KTGYGN4GWG"
};

const vapid = "BEwFx4HVExNBk6h6-UGMbJ6fMyt4kzhpU1W7IPpUknB0rCWQQpQMKDW7-4azlboh4rgY9juL5MEClN0us-KEG5E";

export interface FCMResponse {
  data?: {
    body: string,
    payload?: any,
    receiver: string,
    sender: string,
    title: string,
    type: string
  },
  notification? : {
    title?: string,
    body?: string,
  }
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  app?: FirebaseApp;
  messaging?: Messaging;

  lastUserID? : string | number;
  token?: string;

  private fcmMessageSubject = new Subject<FCMResponse>();

  constructor(
    private user: UserAuthService,
    private android: AndroidPluginService,
    private logger: LoggerService
  ) {
    // this.initFirebase();
  }

  private initFirebase(){
    if (!(isPlatform("android") && isPlatform("capacitor"))){
      this.app = initializeApp(firebaseConfig);
      this.messaging = getMessaging(this.app);
    }  
    this.user.getUserDataSubject().subscribe(async res => {
      if (res) {
        const userID = res.data_user.id;

        //First login
        if (this.lastUserID === undefined){
          this.registerToken();
          this.lastUserID = userID;
          return;
        }
        
        //If user changed
        if (this.lastUserID != userID){
          await this.unregisterToken();
          this.registerToken();
          this.lastUserID = userID;
        }
        
      //User logged out
      } else {
        this.lastUserID = undefined;
        this.unregisterToken();
      }
    })
  }

  async registerToken(){
    if (isPlatform("android") && isPlatform("capacitor")){
      //If Android
      try {
        const {token} = await this.android.registerFCM();
        addTokenFCM(token);
        this.token = token;
        this.logger.log("FCM Token", token);        
        this.android.addFCMListener( res => {
          this.logger.log("New FCM message", res);
          //If on new message
          if (res?.type == "messageReceived") {
            if (res?.response !== undefined) this.fcmMessageSubject.next(res.response)
          } else  if (res?.type == "newToken"){
            //TO DO
          }
      });
      } catch (error) {
        this.logger.error(error);
      }
    } else if (isPlatform("ios") && isPlatform("capacitor")) {
      //Reserved for iOS
    } else {
      //If web
      if (!this.messaging) return;
      try {
        const token = await getToken(this.messaging, {vapidKey: vapid})
        addTokenFCM(token);
        this.token = token;
        this.logger.log("FCM Token Web", token);
        onMessage(this.messaging, (res) => {
          this.logger.log("Message received", res);
          if (res.data?.['payload'] !== undefined) res.data['payload'] = JSON.parse(res.data['payload']);
          this.fcmMessageSubject.next(res as FCMResponse);
        })
      } catch (error) {
        this.logger.error(error);
      }
    }    
  }

  async unregisterToken(){
    if (isPlatform("android") && isPlatform("capacitor")){
      //If Android
      try {
        this.android.unregisterFCM();
        this.android.removeFCMListener();
        if (this.token) await deleteTokenFCM(this.token);
        this.token = undefined;
      } catch (error) {
        this.logger.error(error);
      }
    } else if (isPlatform("ios") && isPlatform("capacitor")) {
      //Reserved for iOS
    } else {
      //If web
      try {
        if (this.messaging) await deleteToken(this.messaging).then(res => {
          if (this.token) deleteTokenFCM(this.token);
          this.token = undefined;
        })
      } catch (error) {
        this.logger.error(error);
      }
    }       
  }  

  getFCMMessageObservable() {
    return this.fcmMessageSubject as Observable<FCMResponse>;
  }
}
