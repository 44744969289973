import { Injectable } from '@angular/core';
import { isPlatform } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { ping, pingMobile } from '../api/global.service';
import { UserData } from '../api/user.service';
import { UserAuthService } from '../user/user-auth.service';

const TIMEOUT_MS = 10000;

@Injectable({
  providedIn: 'root'
})
export class PingService {

  private isMobile: boolean = false;
  private userData: UserData | null = null;
  
  timeout?: any;

  constructor(
    private toastr: ToastrService,
    private user: UserAuthService
  ) {
    this.isMobile = isPlatform("android") && isPlatform("capacitor");
    this.ping();
    if (!this.isMobile) return;
    this.user.getUserDataSubject().subscribe(res => {
      this.userData = res;
      this.ping();
    })
  }

  private async ping(){
    this.stopPing(); //Clear last scheduled ping
    try {
      await Promise.allSettled([
        ping(),
        this.isMobile && this.userData !== null ? pingMobile(): Promise.resolve()
      ]);      
      //Schedule next ping
      this.timeout = setTimeout(()=>{
        this.ping();
      }, TIMEOUT_MS);
    } catch (error) {
      this.toastr.error("Your internet connection is not stable.");
    }
  }

  stopPing(){
    if (this.timeout){
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }
}
