import serviceConfig from './api.service';

export const addTokenFCM = async (fcmToken : string) => {
    const res = await serviceConfig({
      url: `fcm/addTokenFCM`,
      method: 'POST',
      data: {fcm_token : fcmToken}
    })
    return res.data;
};

export const deleteTokenFCM = async (fcmToken : string) => {
    const res = await serviceConfig({
      url: `fcm/deleteTokenFCM`,
      method: 'DELETE',
      data: {fcm_token : fcmToken}
    })
    return res.data;
};

export const notifyEmergencyContact = async (payload: {
  mobile_enable_location?: boolean
  mobile_longitude?: number,
  mobile_latitude?: number,
  mobile_code?: string
} = {}) => {
  const res = await serviceConfig({
    url: `fcm/notifyEmergencyContact`,
    method: 'POST',
    data: payload,
  })
  return res.data;
};