import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyThemeSelectorComponent } from './components/my-theme-selector/my-theme-selector.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MyThemesService } from './services/my-themes.service';



@NgModule({
  declarations: [
    MyThemeSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
  ],
  exports: [
    MyThemeSelectorComponent
  ],
  providers: [
    MyThemesService
  ]
})
export class MyThemeModule { }
