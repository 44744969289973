import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    firstValueFrom(this.route.queryParams).then(params => {
      this.router.navigate(['auth', 'inviteuser'], {queryParams: params});
    })
   }
}
