import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { Subscription } from 'rxjs';
import { EmergencyService } from '../../services/emergency.service';

@Component({
  selector: 'app-emergency-popup',
  templateUrl: './emergency-popup.component.html',
  styleUrls: ['./emergency-popup.component.scss']
})
export class EmergencyPopupComponent implements OnDestroy {

  readonly path = '/assets/images/icons/';

  @ViewChild('modal') modal !: ModalDirective;

  @Input() name: string = 'default'; 

  emergencyServices = [
    {
      label: 'Bantuan Medis',
      icon: this.path + 'ambulance.png'
    },
   /*  {
      label: 'Derek & Bengkel',
      icon: this.path + 'tow-truck.png'
    }, */
    {
      label: 'Damkar',
      icon: this.path + 'fire-truck.png'
    },
    {
      label: 'Polisi',
      icon: this.path + 'police-car.png'
    }
  ]

  private sub!: Subscription;

  constructor(private svc: EmergencyService) {
    this.sub = this.svc.observable.subscribe(data => {
      if (this.name == data.name) {
        this.show();
      }
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  show(){
    this.modal.show();
  }
}
