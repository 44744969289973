import { Component, Input } from '@angular/core';
import { EmergencyService } from '../../services/emergency.service';

@Component({
  selector: 'app-emergency-button',
  templateUrl: './emergency-button.component.html',
  styleUrls: ['./emergency-button.component.scss']
})
export class EmergencyButtonComponent {

  @Input() name: string = 'default';
  @Input() labelBreakpoint?: 'sm' | 'md' | 'lg' | 'xs' | 'xl';

  constructor(
    private svc: EmergencyService
  ) { }

  showPopup(){
    this.svc.showModal(this.name)
  }

}
