import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmergencyService {

  private subject: Subject<{name: string}> = new Subject()
  get observable(){
    return this.subject.asObservable();
  }
  constructor() { }

  showModal(name: string = 'default'){
    this.subject.next({name: name})
  }
}
