import serviceConfig from './api.service';

//Language

export const getMstLanguage = async (
  params: object = {}
) => {
  const res = await serviceConfig({
    url: `master/getMstLanguage`,
    method: 'GET',
    params
  })
  return res.data;
};

export const getMstLanguagePair  = async (
  id_lang: string | number,
  ) => {
    const res = await serviceConfig({
      url: `master/getMstLanguagePair/${id_lang}`,
      method: 'GET',  
    })
    return res.data;
  };

//Language - END

//User & Company

export const getMstUserCompanyRole  = async (
  id_lang: string | number,
  ) => {
    const res = await serviceConfig({
      url: `master/getMstUserCompanyRole/${id_lang}`,
      method: 'GET',  
    })
    return res.data;
  };

//User & Company - END

//Subject Matters
export const getMstSubjectMatter  = async (
  id_lang: string | number,
  ) => {
    const res = await serviceConfig({
      url: `master/getMstSubjectMatter/${id_lang}`,
      method: 'GET',  
    })
    return res.data;
  };


export const getMstServices = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstServices/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstServicesStatus = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstServicesStatus/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstServicesForRegister = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstServicesForRegister/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstTimezone = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `master/getMstTimezone`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const getMstReportType = async (
  id_mst_language: any
) => {
  const res = await serviceConfig({
    url: `master/getMstReportType/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstReportStatus = async (
  id_mst_language: any
) => {
  const res = await serviceConfig({
    url: `master/getMstReportStatus/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};


export const checkIsAppPublic = async (
  id_mst_language: any
) => {
  const res = await serviceConfig({
    url: `master/checkIsAppPublic/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstProblematicContent = async (
  id_mst_language: any
) => {
  const res = await serviceConfig({
    url: `master/getMstProblematicContent/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstHashtag = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstHashtag/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstServicesSection = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstServicesSection/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstSupportedLogo = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstSupportedLogo/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstWatch = async (
  id_mst_watch_brand: string | number = 0,
  params: any = {}
) => {
  const res = await serviceConfig({
    url: `master/getMstWatch/${id_mst_watch_brand}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstWatchBrand = async (
  id_mst_language: string | number = 0
) => {
  const res = await serviceConfig({
    url: `master/getMstWatchBrand/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstTenagaAhli = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstTenagaAhli/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstParagraph = async (
  id_lang: string | number,
  params?: any
) => {
  const res = await serviceConfig({
    url: `master/getMstParagraph/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstOffices = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstOffices/${id_lang}`,
    method: 'GET'

  })
  return res.data;
};

export const checkIsMaintenance = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/checkIsMaintenance/${id_lang}`,
    method: 'GET'

  })
  return res.data;
};

export const checkExpense = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/checkExpense/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstMedSos = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstMedSos/${id_lang}`,
    method: 'GET'

  })
  return res.data;
};

export const getMstSizes = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstSizes/${id_lang}`,
    method: 'GET'

  })
  return res.data;
};

export const getMstGroupUserAccountType = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstGroupUserAccountType/${id_lang}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstUserAccountType = async (
  id_lang: string | number,
  id_mst_group_user_account_type?: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstUserAccountType/${id_lang}`,
    method: 'GET',
    params: {
      id_mst_group_user_account_type
    }
  })
  return res.data;
};

export const getMstEmploymentType = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstEmploymentType/${id_lang}`,
    method: 'GET'

  })
  return res.data;
};

export const getMstPhysicalAttributes = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstPhysicalAttributes/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstRef = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstRef/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstBlogCategory = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstBlogCategory/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};
export const getMstAdsPeriod = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstAdsPeriod/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstAdsLocation = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstAdsLocation/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstAdsType = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstAdsType/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstDateForFilter = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstDateForFilter/${id_lang}`,
    method: 'GET',
    params: params

  })
  return res.data;
};

export const getMstBlogStatus = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstBlogStatus/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstStatus = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstStatus/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstBilling = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstBilling/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstBillingStatus = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstBillingStatus/${id_lang}`,
    method: 'GET',
  })
  return res.data;
};

export const getMstOrderStatus = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstOrderStatus/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};
export const getMstCurrency = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstCurrency/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};
export const getMstLetsclusiveBank = async (
  id_lang: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstLetsclusiveBank/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstDegree = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstDegree/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstFieldOfStudy = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstFieldOfStudy/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstBillingPeriod = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstBillingPeriod/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstLocationByIpAddress = async (
  ip_address: string
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationByIpAddress/${ip_address}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstLocationByCity = async (
  city: string,
  params: any = null
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationByCity/${city}`,
    method: 'GET',
    params: params
  })
  return res.data;
};
export const getMstStatusForAds = async (
  id_lang: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstStatusForAds/${id_lang}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstLocationCity = async (
  country_code: string,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationCity/${country_code}`,
    method: 'GET',
    params: params
  })
  return res.data;
};


export const getMstLocationCityProgram = async (
  country_code: string,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationCityProgram/${country_code}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const addMstIpAddress = async (
  params: any
) => {
  const res = await serviceConfig({
    url: `master/addMstIpAddress`,
    method: 'POST',
    params: params
  })
  return res.data;
};

export const getMstLocationCountry = async (
  id_mst_language: string | number,
  params: any = {}
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationCountry/${id_mst_language}`,
    method: 'GET',
    params: params
  })
  return res.data;
};
export const getMstBillingType = async (
  id_mst_language: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstBillingType/${id_mst_language}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstSetting = async (
  id_mst_language: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstSetting/${id_mst_language}`,
    method: 'GET'
  })
  return res.data;
};
export const getMstProgramSection = async (
  id_mst_language: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstProgramSection/${id_mst_language}`,
    method: 'GET'
  })
  return res.data;
};

export const getMstLocation = async (
) => {
  const res = await serviceConfig({
    url: `master/getMstLocation`,
    method: 'GET'
  })
  return res.data;
};

export const getMstSubservices = async (
  id_lang: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstSubservices/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstSalaryForFilter = async (
  id_lang: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstSalaryForFilter/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstCategoryForFilter = async (
  id_lang: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstCategoryForFilter/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstLocationCityForFilter = async (
  id_lang: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationCityForFilter/${id_lang}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getMstLocationDefault = async (
  id_mst_language: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getMstLocationDefault/${id_mst_language}`,
    method: 'GET'
  })
  return res.data;
};


export const addMstLocation = async (
  params: any
) => {
  const res = await serviceConfig({
    url: `master/addMstLocation`,
    method: 'POST',
    params: params
  })
  return res.data;
};


export const getTC = async (
  id_bahasa: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getTC/${id_bahasa}`,
    method: 'GET',
  })
  return res.data;
};

export const getPrivacyPolicy = async (
  id_bahasa: string | number,
) => {
  const res = await serviceConfig({
    url: `master/getPrivacyPolicy/${id_bahasa}`,
    method: 'GET',
  })
  return res.data;
};

export const getLetsclusiveCred = async (password: string) => {
  const res = await serviceConfig({
    url: `master/getLetsclusiveCred`,
    data: {
      password: password
    },
    method: 'POST',
  })
  return res.data;
};


export const getMstEventCategory = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstEventCategory/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};


export const getMstEventStatus = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstEventStatus/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};
export const getMstProgramStatus = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstProgramStatus/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

//Credit

export const getMstCreditSimulation = async (
  id_mst_services: string | number,
  amount: number,
  tenor: number
) => {
  const res = await serviceConfig({
    url: `master/getMstCreditSimulation/${id_mst_services}`,
    method: 'GET',
    params: {
      amount,
      tenor
    }
  })
  return res.data;
};

//Credit - END

//Game

export interface MstGameCategory {
  id: number,
  mst_game_category: string,
  image?: string
}

export const getMstGameCategory = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstGameCategory/${id_lang}`,
    method: 'GET',

  })
  return res.data as Promise<{data: MstGameCategory[]}>;
};

export interface MstGameLevel {
  id: number,
  mst_game_level: string
}

export const getMstGameLevel = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstGameLevel/${id_lang}`,
    method: 'GET',

  })
  return res.data as Promise<{data: MstGameLevel[]}>;
};

export interface MstGameQuestionType {
  id: number,
  mst_game_level: string
}

export const getMstGameQuestionType = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstGameQuestionType/${id_lang}`,
    method: 'GET',

  })
  return res.data as Promise<{data: MstGameQuestionType[]}>;
};

export interface MstGameStatus {
  id: number,
  mst_game_status: string,
  color: string
}

export const getMstGameStatus = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstGameStatus/${id_lang}`,
    method: 'GET',

  })
  return res.data as Promise<{data: MstGameStatus[]}>;
};

//Game - END

//Profile

export const getMstNationality = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstNationality/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstResidency = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstResidency/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

//Profile - END

//Translation

export const getMstTranslation = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstTranslation/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

//Translation - END

//Containder

export const getMstContainderServices = async (
  id_lang: string | number
) => {
  const res = await serviceConfig({
    url: `master/getMstContainderServices/${id_lang}`,
    method: 'GET',

  })
  return res.data;
};

export const getMstUniversity = async () => {
  const res = await serviceConfig({
    url: `master/getMstUniversity`,
    method: 'GET',

  })
  return res.data;
};

//Containder - END