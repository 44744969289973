import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { refreshTokenUnverifiedUser, refreshTokenVerifiedUser, refreshTokenTransition } from '../api/user.service';
import { LoggerService } from '../logging/logger.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  private time: number = 450000;  //Refresh token setiap time ms
  private tick: number = 10000; //Check every tick ms
  private retryCount: number = 0;
  private maxRetryCount: number = 5;
  private interval: any;

  private timeRemaining!: number;

  private key = "refreshTimeRemaining";

  constructor(
    private router: Router,
    private logger: LoggerService,
  ) { }

  refreshVerified(time: number = this.time) {
    this.refresh(refreshTokenVerifiedUser, time);
  }

  refreshUnverified(time: number = this.time) {
    this.refresh(refreshTokenUnverifiedUser, time);
  }

  async refreshUnverifiedNoTime() {
    await refreshTokenUnverifiedUser().then(res => {
      localStorage.setItem("token", res.token);
    }).catch(err => {
      this.logger.error(err)
    })
  }

  async refreshVerifiedNoTime() {
    await refreshTokenTransition().then(res => {
      localStorage.setItem("token", res.token);
      localStorage.setItem(this.key, this.time.toString());
    }).catch(err => {
      this.logger.error(err)
    })
  }

  private refresh(promise: () => Promise<any>, time: number = this.time) {
    let self = this;
    self.stopRefresh();
    this.logger.log("refresh token started");
    self.interval = setInterval(() => {
      const timeLeft = localStorage.getItem(self.key);
      if (timeLeft) {
        self.timeRemaining = parseInt(timeLeft);
      } else {
        self.timeRemaining = self.time;
        localStorage.setItem(self.key, self.timeRemaining.toString());
      }
      self.timeRemaining -= self.tick;
      localStorage.setItem(self.key, self.timeRemaining.toString());

      if (this.timeRemaining <= 0) {
        promise().then((res) => {
          localStorage.setItem("token", res.token);
          localStorage.removeItem(self.key);
          self.retryCount = 0;
          self.refresh(promise);
        }).catch((error) => {
          self.logger.error(error);
          if (self.retryCount > self.maxRetryCount) {
            self.router.navigate(["auth/login"]);
            return;
          }
          self.retryCount++;
          self.refresh(promise, 1000);
        });
      }
    }, self.tick);
  }

  stopRefresh() {
    if (this.interval) {
      this.logger.log("refresh token stopped");
      clearInterval(this.interval);
    }
  }

  isLoggedIn() {
    return localStorage.getItem("token") !== null;
  }
}
