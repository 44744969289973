import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MyTranslateService } from 'src/app/modules/my-translate/my-translate.service';
import { getMstParagraph } from 'src/app/services/api/master.service';
import { LoggerService } from 'src/app/services/logging/logger.service';
import { UserAuthService } from 'src/app/services/user/user-auth.service';

export const START_PAGE_KEY = 'start-page';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent{

  wordingData?: {
    title: string,
    title_2: string,
    wording_3_button: string,
  };

  images : any[] = [
    {
      url: "assets/images/landing/landing-1.png",
      class: 'd-none d-sm-block',
    },
    {
      url: "assets/images/landing/landing-2.png",
      class: '',
    },
    {
      url: "assets/images/landing/landing-3.png",
      class: 'd-none d-sm-block',
    }
  ]

  private translateSub!: Subscription;

  constructor(
    private router: Router,
    private user: UserAuthService,
    private translate: MyTranslateService,
    private logger: LoggerService,
  ) {
    this.translateSub = this.translate.getTranslateSubject().subscribe(res => {
      this.getMstParagraph();
    })
  }

  private async getMstParagraph(){
    try {
      const res =  await getMstParagraph(await this.translate.id_lang, {id_type: 5});
      this.wordingData = res;
    } catch (error) {
      this.logger.error(error);
    }
  }

  start(){
    sessionStorage.setItem(START_PAGE_KEY, "1"); //Set session key
    //If mobile app
    if ((isPlatform("android") || isPlatform("ios")) && isPlatform("capacitor")) {
      this.user.getUserData()?.then(res => {
        if (res) {
          this.router.navigate(['main-app']); //If logged-in, redirect to main-app page
        } else {
          this.router.navigate(['auth']); //Else, redirect to login page
        }
      }).catch(err => {
        //If error, redirect to login page
        this.router.navigate(['auth']);
      })
    } else {
      this.router.navigate(['']);
    }
  }
}
