<div class="start-page landing-background-image d-flex flex-column justify-content-between">
    <div class="start-page-language-select-container">
        <app-language-select [dropdownPosition]="'right'"></app-language-select>
    </div>
    <div class="container-fluid start-page-body-container d-flex flex-fill text-center">
        <div class="m-auto">
            <img class="start-page-logo pt-5 pt-md-0" [src]="'assets/images/logos/logo-horizontal-white.png'">
            <h1 class="mb-3" start-page-title>{{wordingData?.title}}</h1>
            <button class="btn my-button-warning px-5 mt-3" start-page-button (click)="start()">{{wordingData?.wording_3_button ? wordingData?.wording_3_button : "Start"}}</button>
        </div>
    </div>
    <div class="start-page-image-container">
        <div class="d-flex justify-content-center justify-content-lg-between flex-nowrap">
            <div class="image-container" *ngFor="let image of images" [ngClass]="[image?.class]">
                <img class="px-3" [src]="image?.url">
            </div>
        </div>
    </div>    
</div>