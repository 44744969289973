import serviceConfig from './api.service';
import { TableQuery } from '../../models/table-query.model';
import { ApplicationInitStatus } from '@angular/core';

export const getAccountIdFromUsername = async (
  username: string
) => {
  const res = await serviceConfig({
    url: `user/getAccountIdFromUsername/${username}`,
    method: 'GET',
  })
  return res.data;
};

export const registerUser = async (
  payload: AddUserPayload
) : Promise<UserData> => {
  const res = await serviceConfig({
    url: `user/registerUser`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const inviteUser = async (
  payload: {
    email: string,
    is_admin: boolean
  }
) : Promise<{
  message: string,
  data_invited_user: {
    username: string | null,
    email: string,
    invitation_link: string
  }
}> => {
  const res = await serviceConfig({
    url: `user/inviteUser`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const updateAccountVerification = async (
  id: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccountVerification/${id}`,
    method: 'PUT',
    params: params
  })
  return res.data;
};

export const addCompany = async (
  payload: AddUserPayload
) => {
  const res = await serviceConfig({
    url: `company/addCompany`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const updateCompany = async (
  id: string | number,
  payload: AddUserPayload
) => {
  const res = await serviceConfig({
    url: `user/updateCompany/${id}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const addMessage = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addMessage`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const addPost = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addPost`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const addPostComment = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addPostComment`,
    method: 'POST',
    data: payload
  })
  return res.data;
};
export const updateMessage = async (
  id_message: any,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateMessage/${id_message}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};
export const getAllUsersInCompany = async (
  params: TableQuery
) => {
  const res = await serviceConfig({
    url: `user/getAllUsersInCompany`,
    method: 'GET',
    params: params
  })
  return res.data;
};
export const checkPost = async (
) => {
  const res = await serviceConfig({
    url: `user/checkPost`,
    method: 'GET',
  })
  return res.data;
};

export const enableDisableLocation = async (is_share_location : boolean) : Promise<any> => {
  const res = await serviceConfig({
    url: `user/enableDisableLocation`,
    method: 'POST',
    data: {is_share_location}
  })
  return res.data;
};

export const getEnableDisableLocation = async () : Promise<any> => {
  const res = await serviceConfig({
    url: `user/getEnableDisableLocation`,
    method: 'GET',
  })
  return res.data;
};

export const bookmark = async (
  id_services_header: any
) => {
  const res = await serviceConfig({
    url: `user/bookmark/${id_services_header}`,
    method: 'PUT',
  })
  return res.data;
};

export const unbookmark = async (
  id_services_header: any
) => {
  const res = await serviceConfig({
    url: `user/unbookmark/${id_services_header}`,
    method: 'PUT',
  })
  return res.data;
};

export const getAllMessage = async (
  id_services_header: any,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAllMessage/${id_services_header}`,
    method: 'GET',
    params: params
  })
  return res.data;
};
export const getMessageAttachment = async (
  id_message: any,
) => {
  const res = await serviceConfig({
    url: `user/getMessageAttachment/${id_message}`,
    method: 'GET',
  })
  return res.data;
};
export const addUser = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addUser`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export interface AddUserPayload {
  name: string,
  username: string,
  password: string,
  passwordConfirmation?: string,
  email: string,
  phone_number?: string,
  language?: string,
  address?: string,
  kelurahan?: string,
  kecamatan?: string,
  longitude?: string | number,
  latitude?: string | number,
  profesi?: string,
  id_mst_language: number,
  id_mst_location?: string | number | null,
  id_mst_university?: string | number | null,
  id_mst_services: number | null,
  mst_sub_services?: any,
  id_mst_ref?: string | number,
  ref_details?: string,
  is_public: boolean,
  is_sign_up: boolean,
  is_male?: boolean,
  photo_file?: any,
  work_cred_file?: string,
  company_name?: string,
  id_mst_user_account_type?: string | number,
  ip_address?: string | number,
  birthdate?: string,
  country?: string,
  country_code?: string,
  province?: string,
  city?: string,
  district?: string,
  sub_district?: string,
  rt?: string,
  rw?: string,
  timezone_second?: string,
  postal_code?: string,
  update_type?: string | number,
  fcm_token?: string | number,
  id_mst_jenis_fasilitas?: string | number,
  mobile_device?: boolean,
  referral_code?: string,
}

export interface UserData{
  data_user: {
    id: number | string,
    name: string,
    username: string,
    email: string,
    city: string,
    id_mst_language: number | string
    photo_file: string
    is_apps_admin?: boolean,
    own_referral_code?: string
    is_suspend: boolean,
    is_verified: boolean,
    id_mst_user_account_type: string | number,
    mst_user_account_type: string,
    phone_number?: string ,
    id_active_company: string | number | null
  },
  token?: string,
  is_verifyUser_needed?: boolean,
  message: string,
  user_map?: {
    latitude: number,
    longitude: number,
    zoom_level: number,
  }
  flag_credit_history?: boolean,
}

export const updateUser = async (
  user_id: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateUser/${user_id}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateUserProfile = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateUser/0`,
    method: 'PUT',
    data: {
            update_type: 3,
            ...payload
          }
  })
  return res.data;
};

export const updateUserLocation = async (
  country_code: string
) => {
  const res = await serviceConfig({
    url: `user/updateUserLocation/${country_code}`,
    method: 'PUT'
  })
  return res.data;
};

export const updateUserMap = async (
  longitude: number,
  latitude: number,
  zoom_level: number,
) => {
  const res = await serviceConfig({
    url: `user/updateUserMap`,
    method: 'PUT',
    data: {
      longitude,
      latitude,
      zoom_level
    }
  })
  return res.data;
};
export const updateUserMapProgram = async (
  longitude: number,
  latitude: number,
  zoom_level: number,
  id_program: string| number
) => {
  const res = await serviceConfig({
    url: `user/updateUserMapProgram/${id_program}`,
    method: 'PUT',
    data: {
      longitude,
      latitude,
      zoom_level
    }
  })
  return res.data;
};

export const updateBank = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateBank/`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateUserAddress = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateUserAddress`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const loginUser = async (
  payload: LoginUserPayload
) : Promise<UserData> => {
  const res = await serviceConfig({
    url: `user/login`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const changeActiveCompany = async (
  id_company: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/changeActiveCompany/${id_company}`,
    method: 'PUT',
    params: params
  })
  return res.data;
};

export const changeActiveUser = async (
  ) => {
    const res = await serviceConfig({
      url: `user/changeActiveUser`,
      method: 'PUT',
    })
    return res.data;
};

export const checkUserPermission = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/checkUserPermission`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const getUserMapProgram = async (
  id_program :string  | number
) => {
  const res = await serviceConfig({
    url: `user/getUserMapProgram/${id_program}`,
    method: 'GET',
  })
  return res.data;
};

export const checkUserToken = async (
) : Promise<{result: boolean}> => {
  const res = await serviceConfig({
    url: `user/checkUserToken`,
    method: 'GET',
  })
  return res.data;
};

export const checkPrivateToken = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/checkPrivateToken`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const getUserDetailsByToken = async (
  ) : Promise<UserData> => {
    const res = await serviceConfig({
      url: `user/getUserDetailsByToken`,
      method: 'GET',
    })
    return res.data;
};

export const getUserDetailsByUsername = async (username: string) : Promise<UserData> => {
    const res = await serviceConfig({
      url: `user/getUserDetailsByUsername/${username}`,
      method: 'GET',
    })
    return res.data;
};

export const addAccountExperience = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addAccountExperience`,
    method: 'POST',
    data: payload
  })
  return res.data;
};


export const subscribeNewsletter = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/subscribeNewsletter`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const addAccountEducation = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addAccountEducation`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const addAccountCertification = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addAccountCertification`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const addAccountAward = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addAccountAward`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

interface LoginUserPayload {
  email: string,
  password: string,
  ip_address: string
}

interface UserPassword {
  password: string
}

interface RegisterUserChat {
  username: string,
  password: string,
  phone_number: string
}

export const changePasswordModal = async (
  id: string | number,
  payload: {
    new_password: string
  }
) => {
  const res = await serviceConfig({
    url: `user/ChangePasswordThreeDots/${id}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const signOut = async (
  user_id: string | number
) => {
  const res = await serviceConfig({
    url: `user/signOut/${user_id}`,
    method: 'PUT',
  })
  return res.data;
};

export const verifyUser = async (
  user_id: string | number,
  params: {is_verified: boolean}
) => {
  const res = await serviceConfig({
    url: `user/verifyUser/${user_id}`,
    method: 'PUT',
    params: params
  })
  return res.data;
};

export const suspendAccount = async (
  user_id: string | number,
  is_suspend: boolean
) => {
  const res = await serviceConfig({
    url: `user/suspendAccount/${user_id}`,
    method: 'PUT',
    params: {
      is_suspend
    }
  })
  return res.data;
};

export const sendEmailLanding = async (
  param: {
    name: string,
    email: string,
    subject: string,
    message: string
  }
) => {
  const res = await serviceConfig({
    url: `user/sendEmailLanding`,
    method: 'POST',
    params: param
  })
  return res.data;
};

export const refreshTokenVerifiedUser = async (
) => {
  const res = await serviceConfig({
    url: `user/refreshTokenVerifiedUser`,
    method: 'POST',
  })
  return res.data;
};

export const refreshTokenTransition = async (
) => {
  const res = await serviceConfig({
    url: `user/refreshTokenTransition`,
    method: 'POST',
  })
  return res.data;
};

export const refreshTokenUnverifiedUser = async (
) => {
  const res = await serviceConfig({
    url: `user/refreshTokenUnverifiedUser`,
    method: 'POST',
  })
  return res.data;
};

export const getUserDetail = async (
  user_id: string | number
) => {
  const res = await serviceConfig({
    url: `user/getUserDetails/${user_id}`,
    method: 'GET',

  })
  return res.data;
};

export const getAccountDetails = async (
  user_id: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAccountDetails/${user_id}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const addUserToCompany = async (
  id_user: any,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/addUserToCompany/${id_user}`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

export const updateUserToCompany = async (
  id_user_company: any,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateUserToCompany/${id_user_company}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};
export const deleteUserToCompany = async (
  id_user_company: any,
) => {
  const res = await serviceConfig({
    url: `user/deleteUserToCompany/${id_user_company}`,
    method: 'DELETE',
  })
  return res.data;
};
export const getAccountDetailsWithoutToken = async (
  user_id: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAccountDetailsWithoutToken/${user_id}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

/*
export const changeActiveUser = async (
) => {
  const res = await serviceConfig({
    url: `user/changeActiveUser`,
    method: 'PUT',
  })
  return res.data;
};

export const changeActiveCompany = async (
  id_company: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/changeActiveCompany/${id_company}`,
    method: 'PUT',
    params: params
  })
  return res.data;
};
*/

export const changeActiveAccount
 = async (
  id_company: string | number,
  params: any = {}
) : Promise<UserData> => {
  const res = await serviceConfig({
    url: `user/changeActiveAccount/${id_company}`,
    method: 'PUT',
    params: params
  })
  return res.data;
};

export const getAllUserExclude = async (
) => {
  const res = await serviceConfig({
    url: `user/getAllUserExclude`,
    method: 'GET',
  })
  return res.data;
};

export const getAllCompanyForUser = async (
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAllCompanyForUser`,
    method: 'GET',
    params: params
  })
  return res.data;
};


export const getAllUsersForAdmin = async (params: any = {}) => {
  const res = await serviceConfig({
    url: `user/getAllUsersForAdmin/`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getAllUsersInCompanyForAdmin = async (
  id_company: string | number, 
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAllUsersInCompanyForAdmin/${id_company}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const getAllCompanyForUserByIdAccount = async (
  id_account: string | number,
  params: TableQuery
) => {
  const res = await serviceConfig({
    url: `user/getAllCompanyForUserByIdAccount`,
    method: 'GET',
    params: params
  })
  return res.data;
};


export const getUserPatients = async (
  user_id: string | number,
  params: TableQuery
) => {
  const res = await serviceConfig({
    url: `user/getUserPatients/${user_id}`,
    method: 'GET',
    params: params
  })
  return res.data;
};


export const deleteAccount = async (
  delete_type: string | number,
  params: { id_account: string | number }
) => {
  const res = await serviceConfig({
    url: `user/deleteAccount/${delete_type}`,
    method: 'DELETE',
    params: params
  })
  return res.data;
};

export const deleteUser = async (
  user_id: string | number,
  params: { delete_type: string | number }
) => {
  const res = await serviceConfig({
    url: `user/deleteUser/${user_id}`,
    method: 'DELETE',
    params: params
  })
  return res.data;
};

export const deletePostComment = async (
  id_comment: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deletePostComment/${id_comment}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deleteMessage = async (
  id_message: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deleteMessage/${id_message}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deleteAccountExperience = async (
  id_account_exp: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deleteAccountExperience/${id_account_exp}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deleteAccountAward = async (
  id_account_award: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deleteAccountAward/${id_account_award}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deleteAccountEducation = async (
  id_account_edu: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deleteAccountEducation/${id_account_edu}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deleteAccountCertification = async (
  id_account_cert: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deleteAccountCertification/${id_account_cert}`,
    method: 'DELETE',
  })
  return res.data;
};

export const deletePost = async (
  id_post: string | number,
) => {
  const res = await serviceConfig({
    url: `user/deletePost/${id_post}`,
    method: 'DELETE',
  })
  return res.data;
};

export const forgotPassword = async (
  payload: { email: string, }
) => {
  const res = await serviceConfig({
    url: `user/forgotPassword/`,
    method: 'POST',
    data: payload
  })
  return res.data;
};

// settingChangePassword digunakan untuk mengganti password dari dalam
export const settingChangePassword = async (
  payload: {
    old_password: string,
    new_password: string
  }
) => {
  const res = await serviceConfig({
    url: `user/settingChangePassword/`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const setAvailability = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/setAvailability/`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const showUser = async (
  id_user: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/showUser/${id_user}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

// changePassword dari email
export const changePassword = async (
  payload: {
    new_password: string
  }
) => {
  const res = await serviceConfig({
    url: `user/changePassword/`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const getAllUserForChatNotLogin = async (
) => {
  const token = localStorage.getItem("public_chat_token");
  const res = await serviceConfig({
    url: `user/getAllUserForChatNotLogin`,
    method: 'GET',
    headers: {
      'Authorization': token ? token : ''
    }
  })
  return res.data;
};

export const sendVerification = async (
  payload: { attachment: string }
) => {
  const res = await serviceConfig({
    url: `user/sendVerification`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateStatus = async (
  user_id: string | number,
  payload: UpdateStatusPayload
) => {
  const res = await serviceConfig({
    url: `user/updateStatus/${user_id}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateAccountExperience = async (
  id_account_exp: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccountExperience/${id_account_exp}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateAccountEducation = async (
  id_account_edu: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccountEducation/${id_account_edu}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateAccountCertification = async (
  id_account_cert: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccountCertification/${id_account_cert}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateAccountAward = async (
  id_account_exp: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccountAward/${id_account_exp}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const updateAccount = async (
  id_account: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/updateAccount/${id_account}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const like = async (
  id_post: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/like/${id_post}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export const follow = async (
  id_account: string | number
) => {
  const res = await serviceConfig({
    url: `user/follow/${id_account}`,
    method: 'PUT',
  })
  return res.data;
};

export const unfollow = async (
  id_account: string | number
) => {
  const res = await serviceConfig({
    url: `user/unfollow/${id_account}`,
    method: 'PUT',
  })
  return res.data;
};

export const acceptPendingFollow = async (
  id_account: string | number
) => {
  const res = await serviceConfig({
    url: `user/acceptPendingFollow/${id_account}`,
    method: 'PUT',
  })
  return res.data;
};

export const unlike = async (
  id_post: string | number,
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/unlike/${id_post}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};

export interface UpdateStatusPayload {
  id_mst_status: string | number,
  status: string,
}

export const checkVerification = async () => {
  const res = await serviceConfig({
    url: `user/checkVerification`,
    method: 'GET',
  })
  return res.data;
};
export const checkIsVerified = async () => {
  const res = await serviceConfig({
    url: `user/checkIsVerified`,
    method: 'GET',
  })
  return res.data;
};

export const checkUserAccess = async () => {
  const res = await serviceConfig({
    url: `user/checkUserAccess`,
    method: 'GET',
  })
  return res.data;
};

export const registerPublicUserForChat = async (
  payload: RegisterUserChat
) => {
  const res = await serviceConfig({
    url: `user/registerUserForChat`,
    method: 'POST',
    data: payload
  })

  return res.data;
};

/* --------------------------------------------------------------------------------------------------------------------------- */


export const getAllUser = async (
  params: any,
  id_mst_user_account_type: string | number
) => {
  const res = await serviceConfig({
    url: `user/getAllUser/${id_mst_user_account_type}`,
    method: 'GET',
    params: params,
  })
  return res.data;
};

export const getUserBySystem = async (
  id_mst_services: string | number,
  params: any,
) => {
  const res = await serviceConfig({
    url: `user/getUserBySystem/${id_mst_services}`,
    method: 'GET',
    params: params,
  })
  return res.data;
};

export const getAllUserInCompany = async (
  params: TableQuery,
  id_company: string | number
) => {
  const res = await serviceConfig({
    url: `user/getAllUserInCompany/${id_company}`,
    method: 'GET',
    params: params,
  })
  return res.data;
};
export const getAllCompanies = async (
  id_mst_services: string | number,
  params: {
    id_mst_bidang: any,
    id_mst_jenis_fasilitas: any,
    id_mst_badan_usaha: any
  }
) => {
  const res = await serviceConfig({
    url: `user/getAllCompanies/${id_mst_services}`,
    method: 'GET',
    params: params,
  })
  return res.data;
};

export const getAllPost = async (
  id_account: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAllPost/${id_account}`,
    method: 'GET',
    params: params,
  })
  return res.data;
};

export const getCurrentUserDetails = async () => {
  const res = await serviceConfig({
    url: `user/getCurrentUserDetails`,
    method: 'GET',
  })
  return res.data;
};

export const getAllSidebar = async () => {
  const res = await serviceConfig({
    url: `user/getAllSidebar`,
    method: 'GET',
  })
  return res.data;
};

export const getUserTestimony = async (id_mst_language: string | number = 0) => {
  const res = await serviceConfig({
    url: `user/getUserTestimony/${id_mst_language}`,
    method: 'GET',
  })
  return res.data;
};

export const getAllUsersByReferralCode = async (params: any = {}) => {
  const res = await serviceConfig({
    url: `user/getAllUsersByReferralCode`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const view = async (
  payload: ViewFilePayload
) => {
  const res = await serviceConfig({
    url: `view/`,
    method: 'POST',
    data: JSON.stringify({
      url: payload
    })
  })
  return res.data;
};

export interface ViewFilePayload {
  url: string
}
export const checkPassword = async (
  payload: any
) => {
  const res = await serviceConfig({
    url: `user/checkPassword`,
    method: 'POST',
    data: payload
  })

  return res.data;
};

export const getAllUserForChat = async (
  id_mst_user_account_type: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/getAllUserForChat/${id_mst_user_account_type}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const search = async (
  id_search_type: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/search/${id_search_type}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

export const searchWithToken = async (
  id_search_type: string | number,
  params: any
) => {
  const res = await serviceConfig({
    url: `user/searchWithToken/${id_search_type}`,
    method: 'GET',
    params: params
  })
  return res.data;
};

//Ping to server
export const ping = () => {
  return serviceConfig({
    url: 'user/ping/',
    method: 'GET'
  });
};

export const del = async (
  payload: DeleteFilePayload
) => {
  const res = await serviceConfig({
    url: `delete/`,
    method: 'DELETE',
    data: JSON.stringify({
      path: payload
    })
  })
  return res.data;
};

export interface DeleteFilePayload {
  path: string
}



export const notifyVideoChat = async (
  username: any,
  payload: any
) => {
  const res = await serviceConfig({
    url: `chat/notifyVideoChat/${username}`,
    method: 'PUT',
    data: payload
  })
  return res.data;
};
