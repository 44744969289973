import axios from 'axios';
import { environment } from '../../../environments/environment';

//COPIED FROM SAS. REVIEW LATER

//Temporary Token for testing
const token = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xMzkuNTkuMjQ2LjU1Ojc2NTRcL2FwaVwvdXNlclwvbG9naW4iLCJpYXQiOjE2MDIxNzY3OTQsImV4cCI6MTYwMjE3ODU5NCwibmJmIjoxNjAyMTc2Nzk0LCJqdGkiOiJmUGF2cmxzT24yUmUxVEcyIiwic3ViIjo1LCJwcnYiOiJmNmI3MTU0OWRiOGMyYzQyYjc1ODI3YWE0NGYwMmI3ZWU1MjlkMjRkIiwiaWRfdXNlciI6NSwibmFtZSI6IlplZmEiLCJ1c2VybmFtZSI6IlplZmEiLCJlbWFpbCI6bnVsbCwicGhvbmVfbnVtYmVyIjoiKzYyODEzMjMyMzIzMjEiLCJhbGFtYXQiOiJBQUEiLCJrZWx1cmFoYW4iOiJCQkIiLCJrZWNhbWF0YW4iOiJDQ0MiLCJsb25naXR1ZGUgIjpudWxsLCJsYXRpdHVkZSI6bnVsbCwicHJvZmVzaSI6IkRva3RlciIsImlkX21zdF9wcm9mZXNpIjoxLCJpc192ZXJpZmllZCI6dHJ1ZSwiaXNfbG9naW4iOnRydWUsImlkX21zdF9sYW5ndWFnZSI6MX0.TBYnuM5qCr70WAXJgfjqfOhY0uAS6robzzzGHI26gyE`;

const service = axios.create({
  baseURL: environment.apiConfig.baseURL + '/api/',
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  function (config: any) {
    const originalRequest = config;
    // check public_chat_token first
    if (localStorage.getItem('public_chat_token')) {
      originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('public_chat_token');
    } else {
      originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    }

    // originalRequest.headers['Authorization'] = 'Bearer ' + token;
    return Promise.resolve(originalRequest);
  },
  function (error) {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.error('error', error);
    if (localStorage.getItem('public_chat_token')) {
      localStorage.removeItem('public_chat_token');
      return Promise.reject(error.response);
    }
    const verifyFailed = new RegExp(/Verify Token is Failed./i);
    if (error && error.response) {
      if (error.response.data && verifyFailed.test(error.response.data.exception)) {
        window.location.href = 'otentifikasi/login';
        localStorage.removeItem('token');
      }

      if (error.response.status == 401 || error.response.statusText === 'Unauthorized') {
        const exception = new RegExp(/changeActiveCompany|deleteCompany/i);
        if (!exception.test(error.response.request.responseURL)) {
          window.location.href = 'otentifikasi/login';
          localStorage.removeItem('token');
        }
      }
      return Promise.reject(error.response);
    }
    console.trace("Timeout Error here");
    return Promise.reject('Koneksi internet Anda tidak stabil. Mohon coba lagi nanti');
  }
);

export default service;
