
<div #dropdown class="language-select btn-group h-100" [ngClass]="[theme]" mdbDropdown [autoClose]="false">
    <a
      mdbDropdownToggle
      type="button"
      class="dropdown-toggle btn"
>
        <span class="fi fi-{{selectedData?.country_code  | lowercase}}"></span>
    </a>
  
    <div class="dropdown-menu dropdown-primary" [ngClass]="[dropdownPosition]">
        <form [formGroup]="languageForm" class="w-100">
                <ng-select
                #selectLanguage
                tabindex="0"
                [bindLabel]="'label'"
                [placeholder]="'Select your domain'"
                [notFoundText]="'No searched domain found'"
                [multiple]="false"
                [closeOnSelect]="true"
                [searchable]="true"
                [clearable]="false"
                [clearSearchOnAdd]="true"
                formControlName="language"
                (change)="modelChange($event);dropdown.hide()"
                
            >
                <ng-option [value]="d" *ngFor="let d of data">
                    <span class="fi fi-{{d.country_code | lowercase}}"></span> {{ d.mst_language }}
                </ng-option>
            </ng-select>
        </form>
    </div>
  </div>

