import serviceConfig from './api.service';
import serviceConfigUpload from './api-upload.service';

export const ping = async (
) => {
  const res = await serviceConfig({
    url: `user/ping/`,
    method: 'GET',
  })
  return res.data;
};

export const pingMobile = async (
  ) => {
    const res = await serviceConfig({
      url: `user/pingMobile/`,
      method: 'GET',
    })
    return res.data;
  };

export const upload = async (subject: string, file: File) : Promise<{status: string, url: string}> => {
  const data = new FormData();
  data.append('file', file);

  const res = await serviceConfig({
    url: `upload/${subject}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
  return res.data;
};

export const uploadVideo = async (subject: string, file: File) => {
  const data = new FormData();
  data.append('file', file);

  const res = await serviceConfig({
    url: `uploadVideo/${subject}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
  return res.data;
};

export const uploadFileUncompressed = async (subject: string, file: File) => {
  const data = new FormData();
  data.append('file', file);

  const res = await serviceConfig({
    url: `uploadFileUncompressed/${subject}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
  return res.data;
};

export const viewFile = async (
  payload: ViewFilePayload
) => {
  const res = await serviceConfig({
    url: `viewFile/`,
    method: 'POST',
    data: JSON.stringify({
      url: payload
    })
  })
  return res.data;
};

export const viewFileStorage = async (
  payload: ViewFilePayload,
  params: any
) => {
  const res = await serviceConfig({
    url: `viewFileStorage/`,
    method: 'POST',
    params: params,
    data: JSON.stringify({
      url: payload
    })
  })
  return res.data;
};

export interface ViewFilePayload {
  url: string
}

export const download = async (
  path: string
) => {
  const res = await serviceConfig({
    url: `download/`,
    method: 'POST',
    data: { url: path },
  })
  return res.data;
};

export interface DownloadFilePayload {
  url: string
}

export const deleteFile = async (
  path: string
) => {
  const res = await serviceConfig({
    url: `delete/`,
    method: 'DELETE',
    data: { path: path }
  })
  return res.data;
};

// For unverified users
export const uploadFileUnverified = async (subject: string, file: File) => {
  const data = new FormData();
  data.append('file', file);

  const res = await serviceConfig({
    url: `uploadFileUnverified/${subject}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
  return res.data;
};


export const viewFileUnverified = async (
  payload: ViewFilePayload
) => {
  const res = await serviceConfig({
    url: `viewFileUnverified/`,
    method: 'POST',
    data: JSON.stringify({
      url: payload
    })
  })
  return res.data;
};

export const downloadFileUnverified = async (
  payload: DownloadFilePayload
) => {
  const res = await serviceConfig({
    url: `downloadFileUnverified/`,
    method: 'POST',
    data: payload
  })
  return res.data;
};


export const deleteFileUnverified = async (
  path: string
) => {
  const res = await serviceConfig({
    url: `deleteFileUnverified/`,
    method: 'DELETE',
    data: { path: path }
  })
  return res.data;
};


export const downloadFile = async (
  path: string
) => {
  const res = await serviceConfig({
    url: 'download',
    method: 'POST',
    data: JSON.stringify({
      url: path,
    }),
    responseType: 'blob',
  });
  return res.data as Promise<Blob>;
};

export const dynamicAPI = async (
  id_lang: any,
  params: any
) => {
  return serviceConfig({
    url: `dynamic/${id_lang}`,
    method: 'GET',
    params: params
  });
};

export const uploadNoTimeout = async (subject: string, file: File) => {
  const data = new FormData();
  data.append('file', file);

  const res = await serviceConfigUpload({
    url: `upload/${subject}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data
  })
  return res.data;
};
