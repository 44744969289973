import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

/**
 * Service for logging into console
 * Disable logging in production build
 * 
 * @export
 * @class LoggerService
 */
@Injectable({
    providedIn: 'root',
})
export class LoggerService {

    prefix = "Logger Service:";
    
    log = (message: any, ...messages: any[])=>{};
    warn = (message: any, ...messages: any[])=>{};
    error = (message: any, ...messages: any[])=>{};
    trace = (message: any, ...messages: any[])=>{};
    table = (tabData: any, ...properties: string[])=>{};

    constructor(){
        if (!environment.production || localStorage.getItem("enable-logger-service")){
            this.log = console.log.bind(window.console, this.prefix);
            this.warn = console.warn.bind(window.console, this.prefix);
            this.error = console.error.bind(window.console, this.prefix);
            this.trace = console.trace.bind(window.console, this.prefix);
            this.table = console.table.bind(window.console, this.prefix);
        }
    }   
}