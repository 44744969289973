import { Component, OnDestroy } from '@angular/core';
import { isPlatform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { MyTranslateService } from 'src/app/modules/my-translate/my-translate.service';
import { getMstSetting } from 'src/app/services/api/master.service';

@Component({
  selector: 'app-app-play-store',
  templateUrl: './app-play-store.component.html',
  styleUrls: ['./app-play-store.component.scss']
})
export class AppPlayStoreComponent implements OnDestroy {

  playStoreImage = "assets/images/app-play-store/google-play.png"
  playStoreURL?: string;

  isShow : boolean = false;

  translateSub!: Subscription;

  constructor(
    private translation: MyTranslateService,
  ) {
    this.isShow = !(isPlatform("android") && isPlatform("capacitor")) && !isPlatform("ios");
    this.translateSub = this.translation.getTranslateSubject().subscribe(res => {
      this.getMstSetting(res?.id_lang)
    })
  }

  ngOnDestroy(): void {
    this.translateSub?.unsubscribe();
  }

  private getMstSetting(id: string | number){
    getMstSetting(id).then(res => {
      this.playStoreURL = res?.phone_android_link;
    })
  }

}
