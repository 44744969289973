export const environmentBase = {
    platform: "web",
    production: false,
    
    mapboxAccessToken: "pk.eyJ1IjoiemVmYW55YS1wdXRyYSIsImEiOiJja2dxbGhxOTMwN2FzMnNwZDg4NGt6N2hiIn0.IqP9FWDxMcQRQw56NhgTug",
    mapboxApiBaseURL: 'https://api.mapbox.com',
    
    googleMapsAPIKey: "AIzaSyDA1IzTzXU-3nO_gMNbFQmjrxK7y0GHlJc",

    pusherKey: 'e0d8fc1bf1b60dce4f09',
    pusherCluster: 'ap1',
    url: 'https://assethub.id/',
    apiConfig: {
      baseURL: 'https://assethub.id/backend',
      storageURL: 'https://assethub.id/'
    },
    breakpoint: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    }
  }
  