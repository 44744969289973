//Angular Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

//Third Party Modules
import { IonicModule } from '@ionic/angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

//Components
import { AppComponent } from './app.component';
import { InviteUserComponent } from './views/pages/invite-user/invite-user.component';
import { AppPlayStoreModule } from './views/components/app-play-store/app-play-store.module';
import { EmergencyModule } from './views/components/emergency/emergency.module';
import { MyThemeModule } from './modules/my-theme/my-theme.module';

@NgModule({
  declarations: [
    AppComponent,
    InviteUserComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MyThemeModule,
    AppPlayStoreModule,
    IonicModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    EmergencyModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
