import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { AndroidPluginService } from './services/android/android-plugin.service';
import { BackButtonService } from './services/back-button/back-button.service';
import { FirebaseService } from './services/firebase/firebase.service';
import { PingService } from './services/ping/ping.service';
import { UserAuthService } from './services/user/user-auth.service';
import { START_PAGE_KEY } from './views/pages/start-page/start-page.component';
import { MyThemesService } from './modules/my-theme/services/my-themes.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: any,
    //private cred: CredentialService,
    private firebase: FirebaseService,
    private user: UserAuthService,
    private androidPluginSvc: AndroidPluginService,
    private ping: PingService,
    private backButton: BackButtonService,
    private theme: MyThemesService
  ){
    // this.redirectToStartPageIfMobile();
    //this.initMidtrans();
    this.backButton.enableBackButtonFunction();
  }

  private redirectToStartPageIfMobile(){
    if (isPlatform("android") && isPlatform("capacitor")){
      if (!sessionStorage.getItem(START_PAGE_KEY)) this.router.navigate(['start-page']);
    }
  }

  /*
  private initMidtrans(){
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://app.midtrans.com/snap/snap.js';
    s.setAttribute('data-client-key', this.cred.getMidtransKey());
    this.renderer2.appendChild(this._document.head, s);
  }
  */

  
}
