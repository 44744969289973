import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserAuthGuard } from './guards/user-auth.guard';
import { RedirectToPlaystoreGuard } from './views/components/app-play-store/guards/redirect-to-playstore.guard';
import { InviteUserComponent } from './views/pages/invite-user/invite-user.component';

export const getMainAppRoute = () => {
  return 'main-app';
}

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/views/pages/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'start-page',
    loadChildren: () => import('src/app/views/pages/start-page/start-page.module').then(m => m.StartPageModule)
  },
  {
    path: 'inviteuser',
    component: InviteUserComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/views/pages/login-register/login-register.module').then(m => m.LoginRegisterModule),
  },
  {
    path: getMainAppRoute(),
    loadChildren: () => import('src/app/views/pages/main-app/main-app.module').then(m => m.MainAppModule),
  },
  {
    path: 'android',
    canActivate: [RedirectToPlaystoreGuard],
    component: RedirectToPlaystoreGuard,
    data: {
      externalUrl: 'https://play.google.com/store/apps/details?id=id.ikd.screenout'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
