import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatform } from '@ionic/angular';
import { Observable, ReplaySubject } from 'rxjs';
import { changeActiveAccount, changeActiveCompany, changeActiveUser, checkUserAccess, checkUserToken, getUserDetailsByToken, updateUserLocation, UserData } from '../api/user.service';
//import { FcmService } from '../firebase/fcm.service';
//import { isPlatform } from '@ionic/angular';
import { LoggerService } from '../logging/logger.service';
import { RefreshTokenService } from './refresh-token.service';

const MINIMUM_GET_NEW_USER_DATA_MS = 1000; //Minimum time before create new request

export interface UserAccess {
  flag_can_add_edit_delete_case?: boolean;
  flag_can_add_user?: boolean;
  flag_can_add_verify_user?: boolean;
  flag_summarization?: boolean;
  flag_summarization_extra_data?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  private userAccess!: UserAccess;
  private userData: UserData | null = null;
  private userAdditionalData: any;

  private userDataBehaviorSubject = new ReplaySubject<UserData | null>(1);
  
  private getUserDataPromise!: Promise<UserData | null> | UserData | null;

  private lastTime: number = Date.now();  

  constructor(
    private router: Router,
    private refresh: RefreshTokenService,
    private logger: LoggerService,
  ) {
    this.getUserData();
    
    if (!(isPlatform('android') && isPlatform('capacitor'))) {
      //Refresh token if in web
      this.getUserDataSubject().subscribe(res => {
        if (res) {
          this.refresh.refreshVerified()
        } else {
          this.refresh.stopRefresh();
        }
      })
    }   
  }

  logIn(access_token: string, userData: UserData) {
    localStorage.setItem("token", access_token);
    this.userDataBehaviorSubject.next(userData);
    this.userData = userData;
  }

  async logOutToHome() {
    this.clearData();
    this.router.navigate(["/"]);
  }

  async logOutToAuth() {
    this.clearData();
    this.router.navigate(["auth"]);
  }

  /*
  getUserData(): Observable<UserData | null>{
    
    this.isTokenValid().then(isValid => {
      if (isValid){
        if (!this.getUserDetailsObservables) {
          this.getUserDetailsObservables = new Observable<UserData>(subscriber => {   
            getUserDetailsByToken().then(data => {
              this.userData = data;
              this.lastTime = Date.now();
              subscriber.next(this.userData);
              subscriber.complete();
            }).catch(error => {
              this.logger.error("getUserData", error);
              subscriber.error(error);
            }).finally(()=> {
              this.getUserDetailsObservables = null;
            })
          })
          firstValueFrom(this.getUserDetailsObservables).then(data => {
            this.userDataBehaviorSubject.next(data);
          })
        }
      } else {
        this.userData = null;
        this.userDataBehaviorSubject.next(this.userData);
      }
    }) 
    return this.userDataBehaviorSubject as Observable<UserData | null>;
  }
  */

  async getUserData(forceNewRequest: boolean = false): Promise<UserData | null> {

    //If User Data exist and not too long ago since last getUserDetails call
    if (this.userData !== null && Date.now() - this.lastTime <= MINIMUM_GET_NEW_USER_DATA_MS && !forceNewRequest) {
      return this.userData;
    }

    if (this.getUserDataPromise && !forceNewRequest) return this.getUserDataPromise;

    this.getUserDataPromise = new Promise<UserData | null>(async (resolve, reject) => {
      try{
        const valid = await this.isTokenValid();
        //Token valid
        if (valid) {
          this.getUserDataPromise = getUserDetailsByToken();
          this.getUserDataPromise.then(data => {
            this.userData = data;
            this.userDataBehaviorSubject.next(data);
            this.getUserDataPromise = null;
            this.lastTime = Date.now();
            resolve(this.userData);
          }).catch(error => {
            this.logger.error("getUserData", error);
            this.getUserDataPromise = null;
            reject(error);
          })
        } else {
          //Token not valid
          this.userData = null;
          this.userDataBehaviorSubject.next(null);
          resolve(null);
        }
      } catch (error) {
        reject(error);
      } finally {
        this.getUserDataPromise = null;
      }
    })
    return this.getUserDataPromise;
  }

  async changeActiveAccount(id: string | number){
    try {
      const res = await changeActiveAccount(id);
      localStorage.setItem("token", res.token as string)
      this.userDataBehaviorSubject.next(res)
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  async changeActiveCompany(id_account: string | number, password: string){
    try {
      const res = await changeActiveCompany(id_account, { password: password });
      localStorage.setItem("token", res.token as string)
      this.getUserData(true);
      return res;
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }

  async changeToUserAccount(){
    try {
      const res = await changeActiveUser();
      localStorage.setItem("token", res.token as string)
      this.getUserData(true);
      return res;
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }
  
  async updateUserLocation(code : string){
    try {
      const res = await updateUserLocation(code);
      localStorage.setItem("token", res?.token);
      this.getUserData(true);
    } catch (error) {
      this.logger.error(error); 
    }
  }

  getUserDataSubject(): Observable<UserData | null>{
    return this.userDataBehaviorSubject as Observable<UserData | null>;
  }

  afterResetPassword() {
    this.clearData();
    this.router.navigate(["/auth/login"]);
  }

  clearData() {
    this.userData = null;
    this.userDataBehaviorSubject.next(this.userData);
    localStorage.removeItem("token");
    localStorage.removeItem('userAdditionalData');
    this.userAdditionalData = {};
  }

  isLoggedIn() {
    return localStorage.getItem("token") !== null;
  }

  async isTokenValid(): Promise<boolean>{
    return new Promise<boolean>((resolve, reject) => {
      if (localStorage.getItem("token") == null) resolve(false);
      checkUserToken().then((res: any) => {
        resolve(res.result);
      }).catch(error => {
        this.logger.error(error)
        reject(error);
      })
    })    
  }

  // isUserChatLoggedIn(){
  //   return localStorage.getItem("public_chat_token") !== null;
  // }

  setUserAdditionalData(key: string, value: string) {
    this.userAdditionalData[key] = value;
    localStorage.setItem("userAdditionalData", JSON.stringify(this.userData));
  }

  getUserAdditionalData(key: string) {
    return this.userAdditionalData[key];
  }

  removeUserAdditionalData(key: string) {
    delete this.userAdditionalData[key];
    localStorage.setItem("userAdditionalData", JSON.stringify(this.userAdditionalData));
  }

  async getUserAccess(): Promise<UserAccess> {
    if (!this.userAccess) {
      this.userAccess = await checkUserAccess();
    }
    return this.userAccess;
  }
}
