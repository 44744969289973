import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { getMstLanguage, getMstTranslation } from 'src/app/services/api/master.service';
import { LoggerService } from 'src/app/services/logging/logger.service';
import { UserAuthService } from 'src/app/services/user/user-auth.service';

interface LanguageData {data: any, id_lang: string | number}

@Injectable({
  providedIn: 'root'
})
export class MyTranslateService {

  //ID Lang
  private _id_lang?: Promise<string | number> | string | number;
  get id_lang() {
    if (typeof this._id_lang == 'string' || typeof this._id_lang == 'number') return this._id_lang as string | number;
    return firstValueFrom(this.idLangSubject);
  }
  set id_lang(value:  Promise<string | number> | string | number) {
    this._id_lang = value;
  }

  private translation : LanguageData = {
    data: {},
    id_lang: 0
  };

  private languages!: Promise<{data: any[]}> | {data: any[]};;
  private cachedTranslation : any = {}

  private translateSubject = new ReplaySubject<LanguageData>(1); 
  private idLangSubject = new ReplaySubject<string | number>(1); 

  constructor(
    private spinner: NgxSpinnerService,
    private user: UserAuthService,
    private logger: LoggerService
  ) { 
    this.getMstLanguage().finally(() => {
      this.user.getUserDataSubject().subscribe(res => {
        let idLang: string | number = 1; //Default ID Lang is 1
        //If logged-in
        if (res) {
          idLang = res.data_user.id_mst_language;
        } 
        //If NOT logged-in
        else {
          //Check local storage
          const localIDLanguage =  localStorage.getItem("id_mst_language");
          
          //Set default language
          //If no local language, set first entry in languages as default language
          if (localIDLanguage == null) {
            const languages = this.languages as {data: any[]}
            if (languages) {
              idLang = languages?.data?.[0]?.id ? languages.data[0].id : 1;
            }
            localStorage.setItem("id_mst_language", idLang!.toString());
          //else
          } else {
            idLang = localIDLanguage;
          }
        }
        if (this._id_lang != idLang) this.idLangSubject.next(idLang);
        this.getMstTranslation();
      })
    })  
  }

  private async getMstTranslation(){
    try {
      const res = await getMstTranslation(await this.id_lang)
      this.translation.data = res.translation;
      this.translation.id_lang = await this.id_lang;
      this.translateSubject.next(this.translation);
    } catch (error) {
      this.logger.error(error);
    }
  }

  getTranslation(text: string){
    const keyArray = text.split(".");
    let result: any = this.translation.data;
    for (let index = 0; index < keyArray.length; index++) {
      const value = result[keyArray[index]];
      if (value == undefined) {
        this.cachedTranslation[text] = -1;
        return undefined;
      }
      result = value;      
    }
    return result as string;
  }

  getTranslateSubject(){
    return this.translateSubject as Observable<LanguageData>;
  }

  //Return translation result, or fallback, or original string
  instant(text: string, fallback?: string){
    const result = this.getTranslation(text)
    return result ? result : fallback !== undefined ? fallback : text;
  }

  async getMstLanguage(): Promise<{data: any[]} | {data: any[]}>{
    if (this.languages) return this.languages;
    const promise = getMstLanguage();
    promise.then(res => {
      this.languages = res;
    })
    this.languages = promise;
    return this.languages;
  }

  async changelanguage(id_lang: number | string){
    try {
      this.spinner.show();
      this.id_lang = id_lang;
      localStorage.setItem("id_mst_language", id_lang.toString());
      await this.getMstTranslation();
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.spinner.hide();
    }
  }
}
