import { Injectable } from '@angular/core';

import { Plugin, PluginListenerHandle, registerPlugin} from '@capacitor/core';
import { isPlatform } from '@ionic/angular';
import { FCMResponse } from '../firebase/firebase.service';

import { LoggerService } from '../logging/logger.service';
import { UserAuthService } from '../user/user-auth.service';

interface AndroidPlugin extends Plugin{
  test(): Promise<void>;
  registerFCM(): Promise<{token: string}>,
  unregisterFCM(): Promise<void>,
  login(data: {token: string}): Promise<void>;
  logout(): Promise<void>;
  saveToFile(payload: {b64: string, filename: string}): Promise<void>;
}

const PLUGIN = registerPlugin<AndroidPlugin>('AndroidPlugin');

@Injectable({
  providedIn: 'root'
})
export class AndroidPluginService {

  private fcmListener : any;

  constructor(
    private logger: LoggerService,
    private user: UserAuthService,
  ){
    //If platform is Android & use Capacitor, subsribe to user login/logout event
    if (isPlatform("capacitor") && isPlatform("android")){
      this.test();
      this.user.getUserDataSubject().subscribe(data => {
        if (!data) {
          this.logout();
        } else {
          this.login();
        }
      })
    }
  }

  private test(){
    PLUGIN.test();
  }

  async addFCMListener(callback : (res : {type: string, response?: FCMResponse}) => void){
    this.removeFCMListener();
    this.fcmListener = await PLUGIN.addListener('fcmEvent', callback)
  }

  removeFCMListener(){
    if (this.fcmListener) this.fcmListener.remove();
  }

  registerFCM(){
    return PLUGIN.registerFCM();
  }

  unregisterFCM(){
    return PLUGIN.unregisterFCM();
  }

  registerLocationPermissionResult(callback: (res : {permission: boolean}) => void) : Promise<PluginListenerHandle>{
    return PLUGIN.addListener('locationPermissionResult', callback);
  }

  login() {
    const token = localStorage.getItem("token");
    if (token === null) return Promise.reject("Token not found")
    return PLUGIN.login({token: token});
  }

  logout() {
    return PLUGIN.logout();
  }

  saveToFile(b64: string, filename: string){
    return PLUGIN.saveToFile({b64, filename});
  }

}
