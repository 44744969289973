<router-outlet></router-outlet>

<app-emergency-popup></app-emergency-popup>

<ngx-spinner 
    bdColor="rgba(255, 255, 255, 1)"
    color="var(--green-1)"
    >
    <div class="d-flex flex-column justify-content-center" style="position:relative; padding-bottom: 60%">
        <img style='width:500px; max-width: 80vw' src='assets/images/logos/logo-horizontal-color.png'/>
        <div style="color: var(--green-1)" class="la-ball-beat la-2x mx-auto">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</ngx-spinner>